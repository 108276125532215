import { Controller } from "@hotwired/stimulus"
import { disablePageScroll, enablePageScroll } from "scroll-lock"

import Stimulus from "../../common/utils/stimulus"

class Header extends Controller {
  static values = {
    sticky: Boolean,
    mobileNav: Boolean,
    userNav: Boolean,
    cart: Boolean,
  }

  static targets = [`nav`, `cta`]

  #stickyPlaceholder = null
  #stickyPlaceholderHeight = 0

  connect() {
    window.addEventListener(`scroll`, this.winScroll, { passive: true })
    window.addEventListener(`resize`, this.trackMobileHeaderHeight)
    document.addEventListener(`turbo:before-cache`, this.turboBeforeCache)
  }

  disconnect() {
    window.removeEventListener(`scroll`, this.winScroll, { passive: true })
    window.removeEventListener(`resize`, this.trackMobileHeaderHeight)
    document.removeEventListener(`turbo:before-cache`, this.turboBeforeCache)
  }

  toggleMobileNav() {
    this.mobileNavValue = !this.mobileNavValue
  }

  toggleCart() {
    this.cartValue = !this.cartValue
    this.userNavValue = false
  }

  toggleUserNav() {
    this.userNavValue = !this.userNavValue
    this.cartValue = false
  }

  stickyValueChanged() {
    if (this.stickyValue) {
      this.#stickyPlaceholder = document.createElement(`div`)
      this.#stickyPlaceholder.style.height = `${
        this.#stickyPlaceholderHeight
      }px`
      this.element.after(this.#stickyPlaceholder)
      document.documentElement.classList.add(`--header-sticky`)
      this.trackMobileHeaderHeight()
    } else if (this.#stickyPlaceholder) {
      this.#stickyPlaceholder.remove()
      this.#stickyPlaceholder = null
      document.documentElement.style.removeProperty(`--header-sticky-height`)
    }
  }

  mobileNavValueChanged() {
    if (this.mobileNavValue) disablePageScroll(this.navTarget)
    else enablePageScroll(this.navTarget)
  }

  winScroll = () => {
    const sticky = window.scrollY > window.innerHeight / 2
    if (sticky) this.#stickyPlaceholderHeight = this.element.offsetHeight
    this.stickyValue = sticky
  }

  trackMobileHeaderHeight = () => {
    if (this.stickyValue)
      document.documentElement.style.setProperty(
        `--header-sticky-height`,
        `${this.element.offsetHeight}px`
      )
  }

  turboBeforeCache = () => {
    this.mobileNavValue = false
    this.userNavValue = false
  }
}

Stimulus.register(`header`, Header)
