import { Controller } from "@hotwired/stimulus"
import Stimulus from "../../common/utils/stimulus"

import A11yDialog from "a11y-dialog"
class Modal extends Controller {
  connect() {
    this.dialog = new A11yDialog(this.element)
    this.dialog.show()
  }

  disconnect() {
    this.dialog.destroy()
  }

  hide() {
    this.element.remove()
  }
}

Stimulus.register(`modal`, Modal)
