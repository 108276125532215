import { Controller } from "@hotwired/stimulus"

import Stimulus from "../utils/stimulus"

class RatingStars extends Controller {
  static targets = [`stars`, `selected`, `ratingform`, `submitbutton`]

  static values = {
    rating: Number,
  }

  connect() {
    console.log(this.submitbuttonTarget)
    this.ratingformTarget.style.opacity = 0
    this.submitbuttonTarget.disabled = true
    this.ratingValue = 0
    this.selectedTarget.style.width = `${this.ratingValue * 20}%`
  }

  mouseOver(e) {
    const increments = Math.floor(this.starsTarget.clientWidth / 10)
    const rating = Math.min(Math.round(e.layerX / increments) / 2, 5)
    this.selectedTarget.style.width = `${rating * 20}%`
  }

  mouseOut(e) {
    this.selectedTarget.style.width = `${this.ratingValue * 20}%`
  }

  click(e) {
    this.ratingformTarget.style.opacity = 1
    this.submitbuttonTarget.disabled = false
    const increments = Math.floor(this.starsTarget.clientWidth / 10)
    this.ratingValue = Math.min(Math.round(e.layerX / increments) / 2, 5)
    this.ratingformTarget.value = this.ratingValue
  }
}

Stimulus.register(`rating-stars`, RatingStars)
