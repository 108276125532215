import { Controller } from "@hotwired/stimulus"

import Stimulus from "../../common/utils/stimulus"

class ProductPage extends Controller {
  static values = {
    sticky: Boolean,
  }

  connect() {
    window.addEventListener(`scroll`, this.winScroll, { passive: true })
  }

  disconnect() {
    window.removeEventListener(`scroll`, this.winScroll, { passive: true })
  }

  winScroll = () => {
    const sticky = window.scrollY > window.innerHeight / 2
    this.stickyValue = sticky
  }
}

Stimulus.register(`product-page`, ProductPage)
